import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import "./confirmation-record.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import { resetRegistration } from "../redux/registration/registrationAction";

const RecordRegistrationPage = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetRegistration())
        return () => {
            dispatch(resetRegistration())
        }
    }, [])

    return (
        <Layout main={false}>
            <SEO title="Registration" />
            <Header />
            <div className={"page"}>
                <h2 className={"page-title"}>Your registration has been saved</h2>

                <p>
                    Please make payment soon to ensure you benefit from the best price.<br />You will receive an email with your login details. Please check your Junk folder</p>
                <p>
                    <p>Union World Conference Registration Team</p>
                </p>
            </div>
            <div className={"content-footer footer-alone"}>
                <a href="https://union2022.urevent.fr/login" className={"sr-btn-next btn btn-info"} >Login</a>
            </div>
        </Layout>
    )
}

export default RecordRegistrationPage
